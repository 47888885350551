



















































































import {
  defineComponent,
  onMounted,
  reactive,
  computed,
} from "@vue/composition-api"
import { TypedComponentProps } from "@/vue-props-types"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import NotificationSetting from "@/models/NotificationSetting"
import { alertContainer } from "@/containers/AlertContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import WithBackPageLayout from "@/layouts/WithBackPageLayout.vue"
import NotificationCard from "./NotificationCard.vue"
import Loading from "@/components/Loading.vue"
import { Props } from "./types"
import User from "@/models/User"
import { MeetingStructure } from "@/models/Meeting"
import { projectsContainer } from "@/containers/ProjectsContainer"

interface State {
  meeting: MeetingStructure | null
  isPrepareRequestFormValid: boolean
  isMeetingAlertNotificationFormValid: boolean
  isLoading: boolean
  members: Array<User>
  prepareRequestNotification: NotificationSetting
  meetingAlertNotification: NotificationSetting
}

const propsOptions = {
  id: {
    type: String,
    required: true,
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      I18nFormattedMessage,
      DefaultButton,
      NotificationCard,
      WithBackPageLayout,
      Loading,
    },
    setup(props) {
      const state = reactive<State>({
        meeting: null,
        isPrepareRequestFormValid: false,
        isMeetingAlertNotificationFormValid: false,
        isLoading: false,
        members: [],
        prepareRequestNotification: new NotificationSetting(),
        meetingAlertNotification: new NotificationSetting(),
      })
      const { getMembers, getContext } = teamsContextContainer.useContainer()
      const {
        state: meetingState,
        updateMeetingStructureAsync,
      } = meetingContainer.useContainer()
      const { showSuccessMessage } = alertContainer.useContainer()
      const { getProjectAsync } = projectsContainer.useContainer()

      onMounted(async () => {
        state.members = (await getMembers()).map(m => new User(m))
        state.meeting =
          meetingState.meetings.value?.find(m => m.id === props.id) || null
        state.meetingAlertNotification =
          state.meeting?.meetingAlertNotification || new NotificationSetting()
        //デフォルト表示を設定する
        if (state.meetingAlertNotification.timeBefore === "0:0:0:0") {
          state.meetingAlertNotification.timeBefore = "0:0:15:0"
        }

        state.prepareRequestNotification =
          state.meeting?.prepareRequestNotification || new NotificationSetting()
        //デフォルト表示を設定する
        if (state.prepareRequestNotification.timeBefore === "0:0:0:0") {
          state.prepareRequestNotification.timeBefore = "0:1:0:0"
        }
      })

      const isValid = computed(
        () =>
          state.isPrepareRequestFormValid &&
          state.isMeetingAlertNotificationFormValid
      )

      const hours = [...Array(24)].map((_, i) => {
        const realHour = i + 1
        return {
          text: realHour.toString(),
          value: `${Math.floor(realHour / 24)}:${Math.floor(
            realHour % 24
          )}:0:0`,
        }
      })

      const minutes = [...Array(12)].map((_, i) => {
        const minute = (i + 1) * 5 //5分間隔
        return {
          text: minute.toString(),
          value: `0:${Math.floor(minute / 60)}:${Math.floor(minute % 60)}:0`,
        }
      })

      const save = async () => {
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        state.isLoading = true
        if (state.meeting)
          await updateMeetingStructureAsync(
            project.siteId,
            context.entityId,
            (state.meeting as MeetingStructure).id,
            new MeetingStructure(
              Object.assign({}, state.meeting, {
                prepareRequestNotification: state.prepareRequestNotification,
                meetingAlertNotification: state.meetingAlertNotification,
              }) as MeetingStructure
            ),
            false
          )
        showSuccessMessage("通知を設定しました")
        state.isLoading = false
      }

      return {
        state,
        isValid,
        hours,
        minutes,
        save,
      }
    },
  }
)
