





























































































import { computed, defineComponent, PropType } from "@vue/composition-api"
import { TypedComponentProps } from "@/vue-props-types"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TextArea from "@/components/textAreas/TextArea.vue"
import Card from "@/components/cards/Card.vue"
import Dropdown from "@/components/dropdowns/Dropdown.vue"
import { i18nContainer } from "@/containers/I18nContainer"
import NotificationSetting from "@/models/NotificationSetting"
import { Props } from "./types"
import User from "@/models/User"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"

const propsOptions = {
  candidateTime: Array,
  candidateRemoveTime: Array,
  members: Array,
  value: NotificationSetting,
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: {
      candidateRemoveTime: {
        type: Array,
      },
      candidateTime: {
        type: Array,
      },
      members: {
        type: Array as PropType<User[]>,
      },
      value: {
        type: NotificationSetting,
        required: true,
      },
    },
    components: {
      I18nFormattedMessage,
      TextArea,
      Card,
      Dropdown,
      UsersAutoComplete,
    },
    setup(props, { emit }) {
      const { formatMessage } = i18nContainer.useContainer()
      const isNotify = computed({
        get: () => {
          return (props.value as NotificationSetting).isNotify
        },
        set: newValue => {
          emit("input", Object.assign(props.value, { isNotify: newValue }))
        },
      })
      const time = computed({
        get: () => {
          return (props.value as NotificationSetting).timeBefore
        },
        set: newValue => {
          emit("input", Object.assign(props.value, { timeBefore: newValue }))
        },
      })
      const removeNotify = computed({
        get: () => {
          return (props.value as NotificationSetting).removeNotify
        },
        set: newValue => {
          emit("input", Object.assign(props.value, { removeNotify: newValue }))
        },
      })
      const removeTime = computed({
        get: () => {
          return (props.value as NotificationSetting).removeAfter
        },
        set: newValue => {
          emit("input", Object.assign(props.value, { removeAfter: newValue }))
        },
      })
      const message = computed({
        get: () => {
          return (props.value as NotificationSetting).message
        },
        set: newValue => {
          emit("input", Object.assign(props.value, { message: newValue }))
        },
      })
      const users = computed({
        get: () => {
          const ret = (props.members as User[]).filter(u =>
            (props.value as NotificationSetting).userIds.some(
              uu => uu === u.userId
            )
          )
          return ret
        },
        set: newValue => {
          emit(
            "input",
            Object.assign(props.value, {
              userIds: newValue.map(u => u.userId),
            })
          )
        },
      })

      const onFormValidityChanged = (isValid: boolean) => {
        emit("valid-change", isValid)
      }

      return {
        isNotify,
        removeNotify,
        time,
        removeTime,
        message,
        users,
        i18nFormattedMessage: formatMessage,
        onFormValidityChanged,
      }
    },
  }
)
